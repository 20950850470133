
import { defineComponent, onMounted, computed, ref, onBeforeUnmount } from 'vue'
import { useMq } from 'vue3-mq'

import HomeBlock from '@web/components.v2/Home/Block.vue'

import { useHomeBanners } from '@web/composition/useBanners'
import Token from '@web/common/token'
import md5 from 'locutus/php/strings/md5'
import urlParse from 'url-parse'
import { analyticsBannerClick, analyticsBannerShow } from '@web/services/Analytics/events'
import useGetImageWithSize from '@web/composition/useGetImageWithSize'

export default defineComponent({
  name: 'HomeNewLevel',
  components: {
    HomeBlock
  },
  emits: ['loaded'],
  setup (props, ctx) {
    const refBanner = ref<HTMLElement | null>(null)
    const isShown = ref(false)

    const {
      bannerDesktop,
      bannerMobile,
      bannerPlatform,
      bannerPlacement
    } = useHomeBanners()
    const mq = useMq()

    const isDesktop = computed(() => mq.desktop)
    const isMobile = computed(() => mq.mobile)
    const banner = computed(() => {
      return isDesktop.value ? bannerDesktop.value : bannerMobile.value
    })
    const position = computed(() => isDesktop.value ? `${bannerPlatform.desktop}_newLevel_${bannerPlacement}` : `${bannerPlatform.mobile}_newLevel_${bannerPlacement}`)

    function getQuery ({ position, bannerID, query }: { position: string, bannerID: string, query: Record<string, string | undefined> }): Record<string, string> {
      const utmMarks: Record<string, string> = {
        utm_source: query.utm_source || 'lk',
        utm_medium: query.utm_medium || 'banner',
        utm_campaign: query.utm_campaign || position,
        utm_term: query.utm_term || bannerID
      }

      return {
        ...query,
        ...utmMarks,
        token: Token.get(),
        _uah: md5(window.navigator.userAgent)
      }
    }

    const url = computed(() => {
      const url = banner.value?.surveyFormUrl || banner.value?.url
      if (url) {
        const urlInstance = urlParse(url, true)
        const query = getQuery({
          position: position.value,
          bannerID: banner.value?.id.toString() || '',
          query: urlInstance.query
        })
        urlInstance.set('query', query)
        return urlInstance.toString()
      }

      return ''
    })

    const image = computed(() => useGetImageWithSize({ image: banner.value?.image, width: isMobile.value ? 'small' : 425 * 2 }))

    function onBannerShow () {
      if (refBanner.value && banner.value) {
        const screenVisiblePosition: number = window.scrollY + window.innerHeight
        const centerBanner = refBanner.value.offsetTop + (refBanner.value.offsetHeight / 2)
        if (screenVisiblePosition > centerBanner && !isShown.value) {
          analyticsBannerShow({ banner: banner.value, position: position.value, url: url.value })
          isShown.value = true
        }
      }
    }

    function onBannerClick () {
      if (banner.value) {
        analyticsBannerClick({ banner: banner.value, position: position.value, url: url.value })
      }
    }

    onMounted(() => {
      ctx.emit('loaded', true)
      window.addEventListener('scroll', onBannerShow, true)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onBannerShow, true)
    })

    return {
      banner,
      refBanner,
      url,
      image,
      onBannerShow,
      onBannerClick
    }
  }
})
